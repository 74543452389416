const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 mb-6 font-batusa mt-4">
      MAGIC
    </h3>
    <div class="flex flex-col gap-1">
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        A metodologia <span class="font-semibold">Engagement MAGIC</span> evidencia a experiência dos colaboradores ao focar em cinco chaves que
        destravam o maior comprometimento:
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">Próposito (meaning)</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Autonomia (autonomy)</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-sky-200">Crescimento (growth)</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">Impacto (impact)</span> e
        <span class="underline decoration-2 underline-offset-4 decoration-white-400">Conexão (connection)</span>.
      </p>
    </div>`,
  title: 'MAGIC',
  powered: 'Powered by',
  decisionwise: 'decisionwise',
  _meaning: {
    title: `Meaning (Propósito)`,
    subTitle: `Propósito (Meaning): Encontrar propósito além das tarefas`,
    message: `Propósito refere-se à percepção de um trabalho significativo para além das responsabilidades, tarefas e cargo. Ele diz respeito ao valor que o trabalho agrega aos colaboradores.`,
  },
  _autonomy: {
    title: `Autonomy (Autonomia)`,
    subTitle: `Autonomia (Autonomy): Moldar o trabalho e o ambiente para maximizar o desempenho`,
    message: `Autonomia fortalece a responsabilidade e a confiança dos colaboradores, permitindo-os decidir e gerir a execução de suas tarefas diárias.`,
  },
  _growth: {
    title: `Growth (Crescimento)`,
    subTitle: `Crescimento (Growth): Evoluir a partir de desafios, aprendizagem e crescimento pessoal e profissional`,
    message: `Crescimento representa a percepção do colaborador em relação a sua evolução pessoal e profissional a paritr de desafios, gerando oportunidades de aprendizagem contínua.`,
  },
  _impact: {
    title: `Impact (Impacto)`,
    subTitle: `Impacto (Impact): Contribuir para os resultados da organização`,
    message: `Impacto destaca a importância de os colaboradores enxergarem como seu trabalho contribui para os resultados maiores da organização.`,
  },
  _connection: {
    title: `Connection (Conexão)`,
    subTitle: `Conexão (Connection): Pertencer a algo além de si mesmo`,
    message: `Conexão engloba o senso de pertencimento à equipe, bem como à cultural organizacional.`,
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 mb-6 font-batusa mt-4">
      MAGIC
    </h3>
    <div class="flex flex-col gap-1">
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        La metodología <span class="font-semibold">Engagement MAGIC</span> resalta la experiencia de los empleados al centrarse en
        cinco claves que desbloquean un mayor compromiso:
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">Propósito (meaning)</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Autonomía (autonomy)</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-sky-200">Crecimiento (growth)</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">Impacto (impact)</span> y
        <span class="underline decoration-2 underline-offset-4 decoration-white-400">Conexión (connection)</span>.
      </p>
    </div>`,
  title: 'MAGIC',
  powered: 'Powered by',
  decisionwise: 'decisionwise',
  _meaning: {
    title: `Meaning (Propósito)`,
    subTitle: `Propósito (Meaning): Encontrar propósito más allá de las tareas`,
    message: `El propósito se refiere a la percepción de un trabajo significativo más allá de las responsabilidades, tareas y cargos. Se refiere al valor que el trabajo agrega a los empleados.`,
  },
  _autonomy: {
    title: `Autonomy (Autonomía)`,
    subTitle: `Autonomía: Moldear el trabajo y el ambiente para maximizar el desempeño`,
    message: `La autonomía fortalece la responsabilidad y la confianza de los colaboradores, permitiéndoles decidir y gestionar la ejecución de sus tareas diarias.`,
  },
  _growth: {
    title: `Growth (Crecimiento)`,
    subTitle: `Crecimiento: Evolucionar a partir de desafíos, aprendizaje y crecimiento personal y profesional`,
    message: `El crecimiento representa la percepción del colaborador respecto a su evolución personal y profesional a partir de desafíos, generando oportunidades de aprendizaje continuo.`,
  },
  _impact: {
    title: `Impact (Impacto)`,
    subTitle: `Impacto: Contribuir a los resultados de la organización`,
    message: `El impacto destaca la importancia de que los colaboradores vean cómo su trabajo contribuye a los resultados mayores de la organización.`,
  },
  _connection: {
    title: `Connection (Conexión)`,
    subTitle: `Conexión: Pertenecer a algo más allá de uno mismo`,
    message: `La conexión abarca el sentido de pertenencia al equipo, así como a la cultura organizacional.`,
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal underline decoration-4 underline-offset-8 decoration-orange-400 mb-6 font-batusa mt-4">
      MAGIC
    </h3>
    <div class="flex flex-col gap-1">
      <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal dark:text-muted-200 text-muted-800 text-left">
        The <span class="font-semibold">Engagement MAGIC</span> methodology highlights employee experience by focusing
        on five keys that unlock engagement:
        <span class="underline decoration-2 underline-offset-4 decoration-orange-400">Meaning</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-teal-400">Autonomy</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-sky-200">Growth</span>,
        <span class="underline decoration-2 underline-offset-4 decoration-yellow-400">Impact</span> and
        <span class="underline decoration-2 underline-offset-4 decoration-white-400">Connection</span>.
      </p>
    </div>`,
  title: 'MAGIC',
  powered: 'Powered by',
  decisionwise: 'decisionwise',
  _meaning: {
    title: `Meaning`,
    subTitle: `Meaning: Find purpose beyond tasks`,
    message: `Purpose refers to the perception of meaningful work beyond responsibilities, tasks, and position. It relates to the value that the work adds to the employees.`,
  },
  _autonomy: {
    title: `Autonomy`,
    subTitle: `Autonomy: Shape work and environment to maximize performance`,
    message: `Autonomy strengthens the responsibility and trust of employees, allowing them to decide and manage the execution of their daily tasks.`,
  },
  _growth: {
    title: `Growth`,
    subTitle: `Growth: Evolve from challenges, learning and personal and professional growth`,
    message: `Growth represents the employee's perception of their personal and professional evolution through challenges, generating opportunities for continuous learning.`,
  },
  _impact: {
    title: `Impact`,
    subTitle: `Impact: Contribute to the organization's results`,
    message: `Impact highlights the importance of employees seeing how their work contributes to the larger results of the organization.`,
  },
  _connection: {
    title: `Connection`,
    subTitle: `Connection: Belong to something other than oneself`,
    message: `Connection encompasses the sense of belonging to the team as well as to the organizational culture.`,
  },
};

export default {
  key: 'magic',
  translations: {
    ptBr,
    es,
    en,
  },
};
