import { defu } from 'defu';
import translations from '~/translations';

const base = {
  wb: {
    //@todo add ao winx-base
    viewMore: 'Ver todos',
    demo: 'Versión de Demostración',
    invite: 'Conozca también la experiencia de nuestro',
    survey: 'Formulario de Encuesta',
    _action: {
      back: 'Volver',
    },
  },
  base: {
    // Traduções específicas do projeto
    _research: {
      lastResearch: 'Últimas Encuestas',
      employees: 'Colaboradores',
      totalEmployees: 'Total de Colaboradores',
      respondents: 'Respondentes',
      participation: 'Participación',
      participationGeneral: 'Participación General',
      info: 'Información',
      favorability: 'Favorabilidad',
      card: `
        ¡Bienvenido(a) al panel de control Winx! Navegue fácilmente por los datos detallados de las encuestas sobre el compromiso de los empleados y la cultura de {companyName}.
        Explore una rica variedad de índices y obtenga una visión profunda de diversos aspectos del entorno de trabajo y comportamientos de los empleados.
      `,
      listCard:
        'Explore la colección completa de investigaciones centradas en el compromiso de los empleados y la cultura organizacional. Sumérjase en análisis detallados y obtenga información valiosa que puede transformar la dinámica y el entorno de su empresa.',
      _viewMore: {
        onGoing: 'Tienes una encuesta en curso:',
        remember: 'Recuerda a tus colaboradores que respondan el formulario.',
        belowList: ', a continuación se muestra una lista de todas las encuestas.',
        list: 'Lista de Encuestas',
        noResearch: 'No hay encuestas en curso.',
      },
    },
    _area: {
      _box: {
        respondents: 'Respondentes',
        participation: 'Participación',
        date: 'Fecha',
      },
    },
    _status: {
      finished: 'Finalizada',
      finishedIn: 'Finalizada en ',
      inProgress: 'En Curso',
      notStarted: 'No iniciada',
    },
    _conjunction: {
      from: ' de ',
      until: ' hasta ',
      and: ' y ',
      of: ' Del ',
      to: ' al ',
    },
    hello: 'Hola',
    infoData: 'Datos informativos',
    _ranking: {
      agree: 'Totalmente de acuerdo',
      partiallyAgree: 'Parcialmente de acuerdo',
      neutral: 'Neutral',
      partiallyDisagree: 'Parcialmente en desacuerdo',
      disagree: 'Totalmente en desacuerdo',
      na: 'No se aplica',
    },
    _tipWinnie: {
      title: 'Consejo de Winnie',
      message:
        'La tabla a continuación muestra el porcentaje de\n' +
        'favorabilidad para cada una de las preguntas\n' +
        'que componen el índice analizado. Para reordenar los\n' +
        'resultados, simplemente haga clic en los títulos de cada columna.',
    },
    _buttons: {
      search: 'Mis Búsquedas',
      export: 'Exportar',
      exportFile: 'Exportar .xlsx',
      exportIndexByFilter: 'Exportar Índice por Filtros',
      filter: 'Filtrar',
      activeFilter: 'Filtros Activos',
      reload: 'Limpiar',
      progress: 'Progreso',
      chart: 'Gráfico',
      start: 'Inicio',
      apply: 'Aplicar',
      remove: 'Eliminar',
      select: 'Seleccionar',
    },
    _titles: {
      infoData: 'Datos Informativos',
      percentagePoints: 'Los datos pueden tener una variación de 1 p.p.',
      _select: {
        filter: 'Selecciona un filtro.',
        question: 'Seleccione una pregunta',
        _company: {
          search: 'Buscar empresa',
          title: 'Empresas',
        },
        search: 'Busca una respuesta aquí',
      },
      _filters: {
        filter: 'Filtros',
        search: 'Buscar filtro',
        noShowing: 'Nada que mostrar',
        checklist: 'No hay ninguna lista de verificación para mostrar aquí por ahora.',
      },
      _tables: {
        _header: {
          filter: 'Participación por Filtro',
          evolution: 'Evolución de la Participación',
          distribution: 'Distribución por Filtro',
          cluster: 'Clusters por Filtro',
          anchor: 'Preguntas ancla',
          culture: 'Preguntas - Cultura Percibida',
          flow: 'Flujo por Filtro',
          question: 'Clasificación por Pregunta',
          currentValues: 'Valores Actuales',
        },
        _columns: {
          description: 'Descripción',
          fullyEngaged: 'Totalmente Comprometidos',
          satisfied: 'Satisfechos',
          undecided: 'Indecisos',
          highTurnoverPotential: 'Alto Potencial de Rotación',
          insufficientData: 'Datos Insuficientes',
          validData: 'Datos Válidos',
          questions: 'Preguntas',
          favorable: 'Favorable',
          neutral: 'Neutral',
          unfavorable: 'Desfavorable',
          values: 'Valores',
          cultures: 'Culturas',
        },
      },
      dimensions: 'Dimensiones',
    },
    _insufficientData: {
      title: 'Datos Insuficientes',
      card: 'Actualmente, esta página no tiene suficientes datos para mostrar información. ¡Vuelve pronto para ver los resultados!',
    },
    _insufficientFilter: {
      title: 'Filtros con datos insuficientes',
      description:
        '¡Ups! La selección de filtros que elegiste tiene menos de 5 encuestados. ¡Ajusta los filtros y vuelve a intentarlo!',
      button: 'Limpiar los filtros',
    },
  },
  titleBase: 'Dashboard Winx',
  _pages: {
    _login: {
      aid: 'Patrocinador',
      _step1: {
        title: 'Acceso por @:{option}',
        subtitleOtp: 'Ingrese su @:{option} de trabajo para recibir un código de acceso único.',
        subtitle: 'Ingrese su documento para acceder.',
        placeholder: '@:wb.email de trabajo',
      },
      _step3: {
        title: 'Código de verificación',
        subtitle: 'Enviamos un código de 6 dígitos a su @:{option} de trabajo.',
        resendCode: '¿No recibió el código?',
        resendByEmail: 'Enviar nuevo código por correo electrónico',
        resendByPhone: 'Enviar nuevo código por SMS',
      },
      changeOfAccess: '¿No es la forma de acceso correcta?',
      support: 'Si no encuentra nuestro mensaje con el código, revise su carpeta de SPAM, puede estar allí.',
      comments: 'Si necesita ayuda, simplemente contacte con nuestro {helpCenter}.',
      footer: 'Aumentando la conciencia para la felicidad auténtica.',
      title: 'Acceso unificado',
      resendingAccess: 'Se ha enviado un nuevo código de acceso a su @:{channel}.',
    },
    _research: {},
  },

  // @todo bk das traduções anteriores, remover ao transferir para novo padrão
  options: 'Opciones',
  welcome: {
    intro: 'En esta página encontrará información importante sobre las búsquedas de',
    onGoing: 'Tiene una encuesta en curso:',
    remember: 'Recuerde a sus colaboradores que respondan el formulario.',
    de: 'de',
  },
  resumeInfo: {
    default:
      'En esta página, obtendrá una descripción general de los conocimientos clave extraídos de la encuesta más reciente realizada a sus empleados.​',
    resume:
      'En esta página, obtendrá una descripción general de los conocimientos clave extraídos de la encuesta más reciente realizada a sus empleados.',
    magic:
      'Aquí podrás conocer las 5 claves del MAGIC (significado, autonomía, crecimiento, impacto y conexión) a partir de las respuestas de tus empleados.​',
    engagementClusters:
      'En esta parte, podrá comprender hasta qué punto están comprometidos los empleados de su empresa.​​',
    engagementDimensions:
      'En esta página, descubrirá de dónde proviene la mayor parte del compromiso de los empleados de su empresa.​',
    rankingByFilters:
      'En esta página verás lo que los empleados de su empresa indicaron en la escala. Simplemente elige una pregunta y te mostraremos los números de cada alternativa.​​',
    rankingByQuestion:
      'En esta página, verá qué empleados de su empresa indicaron en la escala en orden según el estado elegido. ',
    adhesion: 'En esta página encontrará la tasa de participación por día y sector de su empresa.​​',
    strengthAndHealthiness:
      'En esta página, verá cómo se sienten sus empleados acerca de la solidez y la salud de la cultura de su empresa.',
    cultureTypes:
      'En esta página, comprenderá la diferencia entre la cultura deseada y la cultura percibida por los empleados de su empresa.​',
    cultureElements:
      'Aquí descubrirás cómo los empleados están alineados con la visión, el propósito, los valores y la misión de su empresa.​',
    dissonance: 'Aquí entenderás cómo es la cultura y los valores actuales según cada tipo de cultura.​',
    valueRanking:
      'Aquí puedes visualizar cómo los empleados ven los valores actuales y deseados de la empresa a través de un ranking.',
    openQuestions:
      'En esta página podrás obtener información más completa y específica con las respuestas a las preguntas abiertas.',
  },
  boosterh: {
    card: 'Boosterh te guiará en tus próximos pasos, como un consultor que te apoyará a ser más consciente y competente en tus desafíos. Boosterh analiza los resultados de cada respuesta al cuestionario aplicado en su empresa y ofrece recomendaciones para aumentar el compromiso y el alto rendimiento. Mientras el empleado recibe sus propias recomendaciones, de forma individual y confidencial, Boosterh ofrece a RH. un escenario general de cómo se puede mejorar el clima y el compromiso.',
    pages: {
      hr: 'Para RRHH',
      leader: 'Para Líderes',
      employee: 'Para Empleados',
    },
    recommendation: {
      hr: 'Recomendaciones - RRHH',
      leader: 'Recomendaciones - Líderes',
      employee: 'Recomendaciones - Empleados',
    },
  },
  pages: {
    'diagnostic-engagement': {
      resume: 'Resumo',
      enps: 'ENPs',
    },
  },
};
export default defineI18nLocale(async () => {
  return defu(base, translations('es'));
});
