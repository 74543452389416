import { defu } from 'defu';
import translations from '@/translations';

const base = {
  wb: {
    //@todo add ao winx-base
    viewMore: 'Ver todas',
    demo: 'Versão Demonstrativa',
    invite: 'Conheça também a experiência do nosso',
    survey: 'Formulário de pesquisa',
    _action: {
      back: 'Voltar',
    },
  },
  base: {
    // Traduções específicas do projeto
    hello: 'Olá',
    _research: {
      lastResearch: 'Últimas pesquisas',
      employees: 'Colaboradores',
      totalEmployees: 'Total de colaboradores',
      respondents: 'Respondentes',
      participation: 'Participação',
      participationGeneral: 'Participação Geral',
      info: 'Informações',
      favorability: 'Favorabilidade',
      card: `
        Bem-vindo(a) ao Dashboard Winx! Navegue facilmente pelos dados detalhados das pesquisas sobre engajamento dos colaboradores e cultura d{companyArticle} {companyName}.
        Explore uma rica variedade de índices e obtenha uma visão profunda de diversos aspectos do ambiente de trabalho e comportamento dos colaboradores.
      `,
      listCard:
        'Explore a coleção completa de pesquisas focadas no engajamento dos colaboradores e na cultura organizacional. Mergulhe em análises detalhadas e obtenha insights valiosos que podem transformar a dinâmica e o ambiente de sua empresa.',
      _viewMore: {
        onGoing: 'Você possui uma pesquisa em andamento:',
        remember: 'Lembre seus colaboradores de responderem o formulário.',
        belowList: ', segue abaixo uma lista com todas as pesquisas.',
        list: 'Lista de pesquisas',
        noResearch: 'Não tem nenhuma pesquisa em andamento.',
      },
    },
    _area: {
      _box: {
        respondents: 'Respondentes',
        participation: 'Participação',
        date: 'Data',
      },
    },
    _status: {
      finished: 'Finalizada',
      finishedIn: 'Finalizada em ',
      inProgress: 'Em andamento',
      notStarted: 'Não iniciada',
    },
    _conjunction: {
      from: ' de ',
      until: ' até ',
      and: ' e ',
      of: ' De ',
      to: ' a ',
    },
    _ranking: {
      agree: 'Concordo Totalmente',
      partiallyAgree: 'Concordo Parcialmente',
      neutral: 'Nem concordo / Nem discordo',
      partiallyDisagree: 'Discordo Parcialmente',
      disagree: 'Discordo Totalmente',
      na: 'Não se Aplica',
    },
    _tipWinnie: {
      title: 'Dica da Winnie',
      message:
        'A tabela abaixo mostra o percentual da\n' +
        'favorabilidade para cada uma das perguntas\n' +
        'que compõe o índice analisado. Para reordernar os\n' +
        'resultados, basta clicar nos títulos de cada coluna.',
    },
    _buttons: {
      search: 'Minhas Pesquisas',
      export: 'Exportar',
      exportFile: 'Exportar .xlsx',
      exportIndexByFilter: 'Exportar Índice por Filtros',
      filter: 'Filtrar',
      activeFilter: 'Filtros Ativos',
      reload: 'Limpar',
      progress: 'Progresso',
      chart: 'Gráfico',
      start: 'Início',
      apply: 'Aplicar',
      remove: 'Remover',
      select: 'Selecionar',
    },
    _titles: {
      infoData: 'Dados informativos',
      percentagePoints: 'Os dados podem ter uma variação de 1 p.p.',
      _select: {
        filter: 'Selecione um filtro.',
        question: 'Selecione uma pergunta',
        _company: {
          search: 'Buscar empresa',
          title: 'Empresas',
        },
        search: 'Busque uma resposta aqui',
      },
      _filters: {
        filter: 'Filtros',
        search: 'Buscar filtro',
        noShowing: 'Nada para mostrar',
        checklist: 'Não há nenhuma lista de verificação para mostrar aqui por enquanto.',
      },
      _tables: {
        _header: {
          filter: 'Participação por Filtro',
          evolution: 'Evolução da Participação',
          distribution: 'Distribuição por Filtro',
          cluster: 'Clusters por Filtro',
          anchor: 'Perguntas-âncora',
          culture: 'Perguntas - Cultura Percebida',
          flow: 'Flow por Filtro',
          question: 'Ranking por Pergunta',
          currentValues: 'Valores Atuais',
        },
        _columns: {
          description: 'Descrição',
          fullyEngaged: 'Totalmente Engajados',
          satisfied: 'Satisfeitos',
          undecided: 'Indecisos',
          highTurnoverPotential: 'Alto Potencial de Turnover',
          insufficientData: 'Dados Insuficientes',
          validData: 'Dados Válidos',
          questions: 'Perguntas',
          favorable: 'Favorável',
          neutral: 'Neutro',
          unfavorable: 'Desfavorável',
          values: 'Valores',
          cultures: 'Culturas',
        },
      },
      dimensions: 'Dimensões',
    },
    _insufficientData: {
      title: 'Dados insuficientes',
      card: 'Atualmente, esta página não possui dados suficientes para exibir informações. Volte em breve para conferir os resultados!',
    },
    _insufficientFilter: {
      title: 'Filtros com dados insuficientes',
      description:
        'Ops, a seleção de filtros que você escolheu tem menos de 5 respondentes. Ajuste os filtros e tente novamente!',
      button: 'Limpar os filtros',
    },
  },
  titleBase: 'Dashboard Winx',
  _pages: {
    _login: {
      aid: 'Apoio',
      _step1: {
        title: 'Acesso por @:{option}',
        subtitleOtp: 'Digite seu @:{option} de trabalho para receber um código de acesso único.',
        subtitle: 'Digite seu documento para acessar.',
        placeholder: '@:wb.email de trabalho',
      },
      _step2: {
        title: 'Código de verificação',
        subtitle: 'Enviamos um código de 6 dígitos para o seu @:{option} de trabalho.',
        resendCode: 'Não recebeu o código?',
        resendByEmail: 'Enviar novo código por e-mail',
        resendByPhone: 'Enviar novo código por SMS',
      },
      changeOfAccess: 'Não é a forma de acesso correta?',
      support:
        'Se você não encontrar uma mensagem da Winnie com o código, dê uma olhada na sua caixa de SPAM. Ela pode estar lá.',
      comments: 'Se precisar de ajuda basta entrar em contato com a gente pela nossa {helpCenter}.',
      footer: 'Winx - Ampliar consciência para felicidade autêntica.',
      title: 'Acesso unificado',
      resendingAccess: 'Um novo código de acesso foi enviado para o seu @:{channel}.',
    },
    participationGeneral: 'Participação Geral',

    _tipWinnie: {
      title: 'Dica da Winnie',
      message:
        'A tabela abaixo mostra o percentual da\n' +
        'favorabilidade para cada uma das perguntas\n' +
        'que compõe o índice analisado. Para reordernar os\n' +
        'resultados, basta clicar nos títulos de cada coluna.',
    },
    _graphic: {
      agree: 'Concordo Totalmente',
      partAgree: 'Concordo Parcialmente',
      neutral: 'Nem concordo / Nem discordo',
      partDisagree: 'Discordo Parcialmente',
      disagree: 'Discordo totalmente',
      na: 'Não se aplica',
    },
    _tables: {
      _titles: {
        questionRanking: 'Ranking por Pergunta',
        moreFavorable: 'Perguntas com Maior Favorabilidade',
        lessFavorable: 'Perguntas com Menor Favorabilidade',
      },
      _header: {
        participationEvolution: 'Evolução da Participação',
        filterDistribution: 'Distribuição por Filtro',
        clustersByFilter: 'Clusters por Filtro',
        anchorQuestions: 'Perguntas Âncoras',
        participationByFilter: 'Participação por Filtro',
        questionsCulture: 'Perguntas - Cultura Percebida',
        flowByFilter: 'Flow por Filtro',
      },
      _columns: {
        description: 'Descrição',
        fullyEngaged: 'Totalmente Engajados',
        keyContributors: 'Contribuidores Chave',
        opportunityGroup: 'Grupo de Oportunidade',
        fullyDisengaged: 'Totalmente Desengajados',
        satisfied: 'Satisfeitos',
        undecided: 'Indecisos',
        highTurnoverPotential: 'Alto Potencial de Turnover',
        favorable: 'Favorável',
        filter: 'Filtro',
        neutral: 'Neutros',
        promoters: 'Promotores',
        detractors: 'Detratores',
        unfavorable: 'Desfavorável',
        delta: 'Delta',
        participation: 'Participação',
        name: 'Nome',
        votes: 'Votos',
        daily: 'Dia a Dia',
        grossValue: 'Valor Bruto',
        percentage: 'Percentual',
        values: 'Valores',
        cultures: 'Culturas',
        yes: 'Sim',
        no: 'Não',
        NA: 'Não Aplicável',
        innovateAndEntrepreneur: 'Inovar e Empreender',
        sellAndCompete: 'Vender e Competir',
        planAndControl: 'Planejar e Controlar',
        collaborateAndCoCreate: 'Colaborar e Co-Criar',
        questions: 'Perguntas',
        favorability: 'Favorabilidade',
        inFlow: 'Em Flow',
        enteringFlow: 'Entrando em Flow',
        outFlow: 'Fora do Flow',
        insufficientData: 'Dados Insuficientes',
      },
    },
    _select: {
      select: 'Selecione uma opção para filtrar os dados da tabela.',
      chooseQuestion: 'Escolha uma pergunta para filtrar os dados do gráfico e da tabela.',
      searchAnswers: 'Campo para buscar respostas.',
      searchAnswer: 'Campo para buscar uma resposta da lista.',
      filterByQuestion: 'Filtre por uma pergunta',
      searchResponse: 'Busque uma resposta aqui.',
      responseList: 'Lista de Respostas',
      choose: 'Escolha uma pergunta',
    },
    _adhesion: {
      title: 'Participação',
      message:
        'Visualizar a participação na pesquisa de modo consolidado permite ver os dados gerais de toda a empresa. Além disso, você consegue filtrar os resultados, possibilitando uma análise detalhada da adesão em cada uma das áreas específicas da organização.',
    },
    _attritionClusters: {
      title: 'Clusters de Attrition',
      message:
        'Nosso diagnóstico revelará o risco de perda de talentos na sua organização, classificando colaboradores em quatro grupos:',
      _emoji: {
        engaged: 'Dedicados e entusiasmados com o futuro na empresa',
        satisfied: 'Focados no presente',
        indecisive: 'Com comprometimento em declínio',
        improve: 'Desengajados e de olho em novas oportunidades',
      },
    },
    _cultureElements: {
      title: 'Elementos da Cultura e da Estratégia',
      message1:
        'Os elementos da cultura e estratégia organizacional são cruciais para o sucesso e o engajamento dos funcionários.',
      message2:
        'Quando uma cultura forte, fundamentada nestes elementos, se alinha com uma estratégia clara, a experiência do funcionário é maximizada.',
      _msg1: {
        label: 'Propósito',
        article: 'O',
        element: 'propósito',
        continuation: 'da organização define seu impacto positivo na sociedade.',
        balloon:
          'O propósito é a força motriz, definindo a razão pela qual a empresa existe. Mais do que lucros, o propósito almeja a transformação e o bem-estar coletivo, inspirando colaboradores e alavancando a motivação e o engajamento. Ele dá sentido ao trabalho cotidiano, alinhando todos os esforços em direção a um objetivo maior e mais significativo.',
      },
      _msg2: {
        label: 'Missão',
        article: 'A',
        element: 'missão',
        continuation: 'direciona as prioridades atuais.',
        balloon:
          'A missão oferece um direcionamento claro sobre as prioridades e ações no presente. Ela define o que precisa ser feito agora para atingir os objetivos estratégicos e contribuir para o propósito maior da empresa. A missão permite que todos na organização entendam seu papel específico e trabalhem de maneira alinhada, promovendo foco, coesão e eficiência.',
      },
      _msg3: {
        label: 'Visão',
        article: 'A',
        element: 'visão',
        continuation: 'projeta um futuro inspirador.',
        balloon:
          'A visão é uma representação do futuro desejado para a empresa. Ela serve como um norte, motivando todos os colaboradores a trabalhar com dedicação e criatividade para alcançar objetivos. Ela é o motor que impulsiona a inovação e o crescimento contínuo, mantendo a empresa competitiva e relevante no longo prazo.',
      },
      _msg4: {
        label: 'Valores',
        article: 'Os',
        element: 'valores',
        continuation: 'preservam os princípios essenciais.',
        balloon:
          'Os valores são os princípios fundamentais que guiam o comportamento e as decisões. Eles expressam o que é importante e inegociável, refletindo a essência da cultura organizacional. Além de orientar as ações internas, os valores também servem como um diferencial competitivo, atraindo clientes, parceiros e talentos que compartilham das mesmas crenças e princípios.',
      },
    },
  },

  // @todo bk das traduções anteriores, remover ao transferir para novo padrão
  and: ' e ',
  hello: 'Olá',
  options: 'Opções',
  welcome: {
    intro: 'Nesta página, você vai encontrar informações importantes sobre as pesquisas da empresa',
    onGoing: 'Você possui uma pesquisa em andamento:',
    remember: 'Lembre seus colaboradores de responderem o formulário.',
    de: 'de',
  },
  resumeInfo: {
    default:
      'Nesta página, você vai ter uma visão geral dos insights principais extraídos da pequisa mais recente feita com seus colaboradores.​',
    resume:
      'Nesta página, você vai ter uma visão geral dos insights principais extraídos da pequisa mais recente feita com seus colaboradores.​',
    magic:
      'Aqui você poderá ter uma visão sobre as 5 chaves do MAGIC (significado, autonomia, crescimento, impacto e conexão) a partir das respostas de seus colaboradores.​',
    engagementClusters:
      'Nesta parte, você poderá entender em que medida os colaboradores da sua empresa estão engajados.​',
    engagementDimensions:
      'Nesta página, você vai saber de onde vem a maior parte do engajamento dos colaboradores da sua empresa.​',
    rankingByFilters:
      'Nesta página, você vai ver o que os colaboradores da sua empresa indicaram na escala. Basta escolher uma pergunta e mostraremos os números para cada alternativa.​',
    rankingByQuestion:
      'Nesta página, você vai ver o que os colaboradores da sua empresa indicaram na escala em uma ordem (de)crescente de acordo com o status escolhido.',
    adhesion: 'Nesta página, você vai encontrar a taxa de participação por dia e setor  da sua empresa.​',
    strengthAndHealthiness:
      'Nesta página, você vai visualizar como os seus colaboradores se sentem em relação à força e à saudabilidade da cultura da sua empresa',
    cultureTypes:
      'Nesta página, você vai entender qual é a diferença entre a cultura desejada e a cultura percebida pelos colaboradores da sua empresa.​',
    cultureElements:
      'Aqui, você vai encontrar como os colaboradores estão alinhados com a visão, o propósito, os valores e a missão da sua empresa.​',
    dissonance: 'Aqui, você vai entender como a cultura e os valores atuais estão de acordo com cada tipo de cultura.',
    valueRanking:
      'Aqui, você vai visualizar como os colaboradores encaram os valores atuais e desejados da empresa por meio de um ranking.',
    openQuestions:
      'Nesta página, você vai poder ter insights mais completos e específicos com as respostas a perguntas abertas. ',
  },
  boosterh: {
    card: 'O Boosterh irá te guiar seus próximos passos, como um consultor que te apoiará a ter mais consciência e competência para seus desafios. ​O Boosterh analisa resultados de cada resposta do questionário aplicado em sua empresa e oferece recomendações para ampliação do engajamento e da alta performance. Enquanto o colaborador recebe suas próprias recomendações, em caráter individual e confidencial, Boosterh oferece ao RH um cenário geral de como o clima e o engajamento podem ser melhorados.',
    pages: {
      hr: 'Para o RH',
      leader: 'Para os Líderes',
      employee: 'Para os Colaboradores',
    },
    recommendation: {
      hr: 'Recomendações - RH',
      leader: 'Recomendações - Líderes',
      employee: 'Recomendações - Colaboradores',
    },
  },
  importantData: 'Segue abaixo um resumo com alguns dos dados importantes da empresa',
  helpCentral: 'Central de Ajuda',
  footer: 'Feito com {icon} pela Winx. Por um RH mais humano, inteligente e preditivo.',
  menus: {
    search: {
      mySearchs: 'Minhas Pesquisas',
      hello: 'Olá Winner,',
      companySearch: 'Abaixo você encontrará todas as pesquisas da empresa',
      access: 'Acessar detalhamento da pesquisa',
    },
    filter: {
      title: 'Filtros',
      combine: 'Combine os filtros para refinar os resultados.',
      clean: 'Limpar',
      apply: 'Aplicar',
    },
    header: {
      home: 'Início',
      surveys: 'Pesquisas',
    },
    user: {
      logout: 'Sair',
    },
    company: {
      list: 'Lista de Empresas',
      select: 'Selecione a empresa que você deseja ver os dados da(s) pesquisa(s).',
    },
  },
  pages: {
    dashboard: 'Dashboard',
    participationGeneral: 'Participação Geral',
    buttons: {
      mySearchs: 'Minhas Pesquisas',
      indexByFilter: 'Índice por Filtros',
      export: 'Exportar',
      filter: 'Filtrar',
      answers: 'Respostas',
      index: 'Índices',
    },
    resume: {
      overallFavorability: 'Favorabilidade Geral',
      contributors: 'Colaboradores',
      respondents: 'Respondentes',
      participationPercentage: 'Percentual de Participação',
    },
    general: {
      details: 'Detalhes',
      participation: 'Participação',
      favorability: 'Favorabilidade Geral',
      rankingByFilters: 'Ranking por Filtros',
      rankingByQuestions: 'Ranking por Perguntas',
      questionRanking: 'Ranking por Pergunta',
      dailyParticipation: 'Participação Diária',
    },
    select: {
      select: 'Selecione uma opção para filtrar os dados da tabela.',
      chooseQuestion: 'Escolha uma pergunta para filtrar os dados do gráfico e da tabela.',
      searchAnswers: 'Campo para buscar respostas.',
      searchAnswer: 'Campo para buscar uma resposta da lista.',
      filterByQuestion: 'Filtre por uma pergunta',
      searchResponse: 'Busque uma resposta aqui.',
      responseList: 'Lista de Respostas',
      choose: 'Escolha uma pergunta',
    },
    tables: {
      titles: {
        questionRanking: 'Ranking por Pergunta',
        moreFavorable: 'Perguntas com Maior Favorabilidade',
        lessFavorable: 'Perguntas com Menor Favorabilidade',
      },
      header: {
        participationEvolution: 'Evolução da Participação',
        filterDistribution: 'Distribuição por Filtro',
        clustersByFilter: 'Clusters por Filtro',
        anchorQuestions: 'Perguntas Âncoras',
        participationByFilter: 'Participação por Filtro',
        questionsCulture: 'Perguntas - Cultura Percebida',
        flowByFilter: 'Flow por Filtro',
      },
      columns: {
        description: 'Descrição',
        fullyEngaged: 'Totalmente Engajados',
        keyContributors: 'Contribuidores Chave',
        opportunityGroup: 'Grupo de Oportunidade',
        fullyDisengaged: 'Totalmente Desengajados',
        satisfied: 'Satisfeitos',
        undecided: 'Indecisos',
        highTurnoverPotential: 'Alto Potencial de Turnover',
        favorable: 'Favorável',
        filter: 'Filtro',
        neutral: 'Neutros',
        promoters: 'Promotores',
        detractors: 'Detratores',
        unfavorable: 'Desfavorável',
        delta: 'Delta',
        participation: 'Participação',
        name: 'Nome',
        votes: 'Votos',
        daily: 'Dia a Dia',
        grossValue: 'Valor Bruto',
        percentage: 'Percentual',
        values: 'Valores',
        cultures: 'Culturas',
        yes: 'Sim',
        no: 'Não',
        NA: 'Não Aplicável',
        innovateAndEntrepreneur: 'Inovar e Empreender',
        sellAndCompete: 'Vender e Competir',
        planAndControl: 'Planejar e Controlar',
        collaborateAndCoCreate: 'Colaborar e Co-Criar',
        questions: 'Perguntas',
        favorability: 'Favorabilidade',
        inFlow: 'Em Flow',
        enteringFlow: 'Entrando em Flow',
        outFlow: 'Fora do Flow',
      },
      row: {
        insufficientData: 'Dados Insuficientes',
      },
    },
    graph: {
      totalResponses: 'Total de Respostas',
      keywords: 'Palavras-chave',
      desiredCulture: 'Cultura Desejada',
      perceivedCulture: 'Cultura Percebida',
      currentCulture: 'Cultura Atual',
      cultureElements: 'Elementos da Cultura',
      rankingByFilters: 'Ranking por Filtros',
      favorability: 'Favorabilidade',
      participation: 'Participação',
    },
    engagement: {
      titles: {
        title: 'Diagnóstico de Engajamento',
        summary: 'Resumo',
        magic: 'MAGIC®',
        engagementClusters: 'Clusters de Engajamento',
        attritionClusters: 'Clusters de Attrition',
        eNPS: 'eNPS',
        flow: 'Flow',
        engagementDimensions: 'Dimensões do Engajamento',
        influencers: 'Influenciadores',
        openQuestions: 'Perguntas Abertas',
        rankingByFilters: 'Ranking por Filtros',
        rankingByQuestions: 'Ranking por Perguntas',
        participation: 'Participação',
        customQuestions: 'Perguntas Customizadas',
      },
      influencers: {
        top5Influencers: 'Top 5 Influenciadores',
        searchInfluencer: 'Campo para buscar um influenciador.',
        searchInfluencerHere: 'Busque um influenciador aqui.',
        influencerList: 'Lista de Influenciadores',
        influencersAndChangeAgents: 'Influenciadores | Agentes de Mudança',
        influencersAndExperts: 'Influenciadores | Experts',
        influencersAndMentors: 'Influenciadores | Mentors',
        changeAgents: 'Agentes de Mudança',
        mentors: 'Mentores',
        experts: 'Experts',
      },
    },
    culture: {
      titles: {
        summary: 'Resumo',
        strengthAndHealthiness: 'Força e Saudabilidade',
        cultureTypes: 'Tipos de Cultura',
        cultureElements: 'Elementos da Cultura',
        dissonance: 'Dissonância',
        efetivity: 'Efetividade de Cultura',
        valueRanking: 'Ranking de Valores',
        openQuestions: 'Perguntas Abertas',
        rankingByFilters: 'Ranking por Filtros',
        rankingByQuestions: 'Ranking por Perguntas',
        participation: 'Participação',
      },
      values: {
        currentValues: 'Valores Atuais',
        desiredValues: 'Valores Desejados',
        centralValue: 'Valor Central',
      },
    },
    family: {
      titles: {
        familyDimensions: 'Dimensões',
      },
    },
    consultor: {
      intro: 'Segue abaixo a devolutiva da sua pesquisa.',
      welcome:
        'O Consultor Inside deve ser utilizado como ferramenta para guiar seus próximos passos. Com um overview completo dos principais dados que merecem atenção, tanto de sucesso quanto de melhoria, recebendo sugestões práticas que o auxiliarão na definição de planos de ação estratégicos e na tomada de decisões.',
    },
  },
};

export default defineI18nLocale(async () => {
  return defu(base, translations('ptBr'));
});
