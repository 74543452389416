import { default as adhesionfbtAdcQjlQMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/adhesion.vue?macro=true";
import { default as attrition_45clustersYhLwDE4kbxMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/attrition-clusters.vue?macro=true";
import { default as indexdEwlAZVd1DMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/index.vue?macro=true";
import { default as _91id_93hLajRhYlUvMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/[id].vue?macro=true";
import { default as _91id_93Iscqwz202wMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/assignment/[id].vue?macro=true";
import { default as _91id_935GMAtKNgFJMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/assignment/task/[id].vue?macro=true";
import { default as _91id_93FS14L008QJMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/plans-[plan]/[id].vue?macro=true";
import { default as indexmuoPytoA0wMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/plans-[plan]/index.vue?macro=true";
import { default as advicesPJoDi37oy6Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/advices.vue?macro=true";
import { default as employee_45recommendationU64kelPbO2Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/employee-recommendation.vue?macro=true";
import { default as hr_45recommendation0K5HSh52ejMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/hr-recommendation.vue?macro=true";
import { default as indexjrPCC1aBRsMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/index.vue?macro=true";
import { default as leader_45recommendationfhxRbSJBjMMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/leader-recommendation.vue?macro=true";
import { default as task_45implementation5BfCquqTAjMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/task-implementation.vue?macro=true";
import { default as highlightsnIkEonWT3cMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/comments/highlights.vue?macro=true";
import { default as indexx04vGvKiXVMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/comments/index.vue?macro=true";
import { default as commentsYCvdDYCcQLMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/comments.vue?macro=true";
import { default as missionPP4arajWviMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/mission.vue?macro=true";
import { default as purpose4t15OuMsFhMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/purpose.vue?macro=true";
import { default as valueXpMS47BnUeMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/value.vue?macro=true";
import { default as visionoVSFAM3bTvMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/vision.vue?macro=true";
import { default as culture_45elementsWlUZJYEWNpMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements.vue?macro=true";
import { default as aspirationalxat7j5JeDxMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types/aspirational.vue?macro=true";
import { default as currentSHkp48yOWPMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types/current.vue?macro=true";
import { default as culture_45typesPS07Ld0Fx4Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types.vue?macro=true";
import { default as questionMcp9y98gNgMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values/question.vue?macro=true";
import { default as valueNrWgCNxONZMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values/value.vue?macro=true";
import { default as culture_45valuesGGKVPNPGGMMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values.vue?macro=true";
import { default as customized_45questionstQ8XL823YPMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/customized-questions.vue?macro=true";
import { default as demographicso2OpIrtsQyMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/demographics.vue?macro=true";
import { default as dimensionyYRu2PbqZVMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/dimension.vue?macro=true";
import { default as dissonanceWuyX1VTpolMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/dissonance.vue?macro=true";
import { default as healthinessZY0FKYtKcYMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness/healthiness.vue?macro=true";
import { default as strengthkK70XIp6IxMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness/strength.vue?macro=true";
import { default as effectiveness4sQFNaMZILMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness.vue?macro=true";
import { default as engagement_45clusters9ZTiLS1zlgMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-clusters.vue?macro=true";
import { default as managersNgXg7un8CMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/manager.vue?macro=true";
import { default as organizationZL73pt1yGPMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/organization.vue?macro=true";
import { default as teamy8wS22yJgbMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/team.vue?macro=true";
import { default as workxOg2h5f8HiMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/work.vue?macro=true";
import { default as engagement_45dimensionqLkFZ4XD6FMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension.vue?macro=true";
import { default as companySvU5EgUgrUMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/company.vue?macro=true";
import { default as defaultP9SPtZXvKdMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/default.vue?macro=true";
import { default as indexHMpFQzq692Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/index.vue?macro=true";
import { default as leadershipvNSh39czXxMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/leadership.vue?macro=true";
import { default as productj5Bp0hmaLUMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/product.vue?macro=true";
import { default as teamplXnznDG4MMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/team.vue?macro=true";
import { default as enpsOKQcVarQRFMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps.vue?macro=true";
import { default as experience_45dimensionPS6utCkbZoMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/experience-dimension.vue?macro=true";
import { default as bodyOYxH66ZCxfMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/body.vue?macro=true";
import { default as feeling0DdI9PsM01Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/feeling.vue?macro=true";
import { default as mindkajAyyNTHlMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/mind.vue?macro=true";
import { default as soulgGACk310NIMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/soul.vue?macro=true";
import { default as family_45dimensionchwNZWiRtdMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension.vue?macro=true";
import { default as flowAizkwUvWycMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/flow.vue?macro=true";
import { default as indexjrMckfdgJlMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/index.vue?macro=true";
import { default as agentsdlMUFqoB8TMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/agents.vue?macro=true";
import { default as expertsN4Sh4HHjwUMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/experts.vue?macro=true";
import { default as mentors3WCQ8dr13qMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/mentors.vue?macro=true";
import { default as influencersyVNInMdxiRMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers.vue?macro=true";
import { default as autonomyYbr2DQxEEHMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/autonomy.vue?macro=true";
import { default as connectionVX6WRBgM4KMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/connection.vue?macro=true";
import { default as growthpxSjYCvd54Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/growth.vue?macro=true";
import { default as impactX9rHrZQv9qMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/impact.vue?macro=true";
import { default as meaningH38BsFRGGGMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/meaning.vue?macro=true";
import { default as magic5bsxwbtBh0Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic.vue?macro=true";
import { default as highlights08ikfrQQlTMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions/highlights.vue?macro=true";
import { default as indexLv3v5PgIOuMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions/index.vue?macro=true";
import { default as open_45questionsczCuhXxD6iMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions.vue?macro=true";
import { default as ranking_45by_45filterQVOXux3WXeMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/ranking-by-filter.vue?macro=true";
import { default as ranking_45by_45questionq4kbXMb6FoMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/ranking-by-question.vue?macro=true";
import { default as resumedVdvOHpj49Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/resume.vue?macro=true";
import { default as _91_91campaign_93_93hcsniueda9Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]].vue?macro=true";
import { default as indexb6lErPlMfHMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/index.vue?macro=true";
import { default as inside_45consultant_45employeevi5WhHnC9IMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/inside-consultant-employee.vue?macro=true";
import { default as login3EW15MZmp6Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/login.vue?macro=true";
import { default as my_45surveysBV5hAAKjSxMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/my-surveys.vue?macro=true";
import { default as indexJ2ngTVvOD0Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/index.vue?macro=true";
import { default as key_45result_45createrimWIn2jhQMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-result-create.vue?macro=true";
import { default as updateuRR19Rbno1Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/update.vue?macro=true";
import { default as viewD23slLvSwTMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/view.vue?macro=true";
import { default as updateHH6um9fvdAMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/update.vue?macro=true";
import { default as objectives_45createbWidTWF19nMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-create.vue?macro=true";
import { default as plans_45createIpscOaKKj7Meta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-create.vue?macro=true";
import { default as okrsrTrxUpcjctMeta } from "/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs.vue?macro=true";
export default [
  {
    name: _91_91campaign_93_93hcsniueda9Meta?.name,
    path: "/:campaign?",
    meta: _91_91campaign_93_93hcsniueda9Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]].vue"),
    children: [
  {
    name: "campaign-adhesion",
    path: "adhesion",
    meta: adhesionfbtAdcQjlQMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/adhesion.vue")
  },
  {
    name: "campaign-attrition-clusters",
    path: "attrition-clusters",
    meta: attrition_45clustersYhLwDE4kbxMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/attrition-clusters.vue")
  },
  {
    name: "campaign-boosterh-question-advice",
    path: "boosterh/:question()/:advice()",
    meta: indexdEwlAZVd1DMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/index.vue")
  },
  {
    name: "campaign-boosterh-question-advice-objective-id",
    path: "boosterh/:question()/:advice()/objective/:id()",
    meta: _91id_93hLajRhYlUvMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/[id].vue")
  },
  {
    name: "campaign-boosterh-question-advice-objective-assignment-id",
    path: "boosterh/:question()/:advice()/objective/assignment/:id()",
    meta: _91id_93Iscqwz202wMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/assignment/[id].vue")
  },
  {
    name: "campaign-boosterh-question-advice-objective-assignment-task-id",
    path: "boosterh/:question()/:advice()/objective/assignment/task/:id()",
    meta: _91id_935GMAtKNgFJMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/objective/assignment/task/[id].vue")
  },
  {
    name: "campaign-boosterh-question-advice-plans-plan-id",
    path: "boosterh/:question()/:advice()/plans-:plan()/:id()",
    meta: _91id_93FS14L008QJMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/plans-[plan]/[id].vue")
  },
  {
    name: "campaign-boosterh-question-advice-plans-plan",
    path: "boosterh/:question()/:advice()/plans-:plan()",
    meta: indexmuoPytoA0wMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/[advice]/plans-[plan]/index.vue")
  },
  {
    name: "campaign-boosterh-question-advices",
    path: "boosterh/:question()/advices",
    meta: advicesPJoDi37oy6Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/[question]/advices.vue")
  },
  {
    name: "campaign-boosterh-employee-recommendation",
    path: "boosterh/employee-recommendation",
    meta: employee_45recommendationU64kelPbO2Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/employee-recommendation.vue")
  },
  {
    name: "campaign-boosterh-hr-recommendation",
    path: "boosterh/hr-recommendation",
    meta: hr_45recommendation0K5HSh52ejMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/hr-recommendation.vue")
  },
  {
    name: "campaign-boosterh",
    path: "boosterh",
    meta: indexjrPCC1aBRsMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/index.vue")
  },
  {
    name: "campaign-boosterh-leader-recommendation",
    path: "boosterh/leader-recommendation",
    meta: leader_45recommendationfhxRbSJBjMMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/leader-recommendation.vue")
  },
  {
    name: "campaign-boosterh-task-implementation",
    path: "boosterh/task-implementation",
    meta: task_45implementation5BfCquqTAjMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/boosterh/task-implementation.vue")
  },
  {
    name: commentsYCvdDYCcQLMeta?.name,
    path: "comments",
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/comments.vue"),
    children: [
  {
    name: "campaign-comments-highlights",
    path: "highlights",
    meta: highlightsnIkEonWT3cMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/comments/highlights.vue")
  },
  {
    name: "campaign-comments",
    path: "",
    meta: indexx04vGvKiXVMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/comments/index.vue")
  }
]
  },
  {
    name: "campaign-culture-elements",
    path: "culture-elements",
    meta: culture_45elementsWlUZJYEWNpMeta || {},
    redirect: culture_45elementsWlUZJYEWNpMeta?.redirect,
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements.vue"),
    children: [
  {
    name: "campaign-culture-elements-mission",
    path: "mission",
    meta: missionPP4arajWviMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/mission.vue")
  },
  {
    name: "campaign-culture-elements-purpose",
    path: "purpose",
    meta: purpose4t15OuMsFhMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/purpose.vue")
  },
  {
    name: "campaign-culture-elements-value",
    path: "value",
    meta: valueXpMS47BnUeMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/value.vue")
  },
  {
    name: "campaign-culture-elements-vision",
    path: "vision",
    meta: visionoVSFAM3bTvMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-elements/vision.vue")
  }
]
  },
  {
    name: "campaign-culture-types",
    path: "culture-types",
    meta: culture_45typesPS07Ld0Fx4Meta || {},
    redirect: culture_45typesPS07Ld0Fx4Meta?.redirect,
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types.vue"),
    children: [
  {
    name: "campaign-culture-types-aspirational",
    path: "aspirational",
    meta: aspirationalxat7j5JeDxMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types/aspirational.vue")
  },
  {
    name: "campaign-culture-types-current",
    path: "current",
    meta: currentSHkp48yOWPMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-types/current.vue")
  }
]
  },
  {
    name: "campaign-culture-values",
    path: "culture-values",
    meta: culture_45valuesGGKVPNPGGMMeta || {},
    redirect: culture_45valuesGGKVPNPGGMMeta?.redirect,
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values.vue"),
    children: [
  {
    name: "campaign-culture-values-question",
    path: "question",
    meta: questionMcp9y98gNgMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values/question.vue")
  },
  {
    name: "campaign-culture-values-value",
    path: "value",
    meta: valueNrWgCNxONZMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/culture-values/value.vue")
  }
]
  },
  {
    name: "campaign-customized-questions",
    path: "customized-questions",
    meta: customized_45questionstQ8XL823YPMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/customized-questions.vue")
  },
  {
    name: "campaign-demographics",
    path: "demographics",
    meta: demographicso2OpIrtsQyMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/demographics.vue")
  },
  {
    name: "campaign-dimension",
    path: "dimension",
    meta: dimensionyYRu2PbqZVMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/dimension.vue")
  },
  {
    name: "campaign-dissonance",
    path: "dissonance",
    meta: dissonanceWuyX1VTpolMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/dissonance.vue")
  },
  {
    name: "campaign-effectiveness",
    path: "effectiveness",
    meta: effectiveness4sQFNaMZILMeta || {},
    redirect: effectiveness4sQFNaMZILMeta?.redirect,
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness.vue"),
    children: [
  {
    name: "campaign-effectiveness-healthiness",
    path: "healthiness",
    meta: healthinessZY0FKYtKcYMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness/healthiness.vue")
  },
  {
    name: "campaign-effectiveness-strength",
    path: "strength",
    meta: strengthkK70XIp6IxMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/effectiveness/strength.vue")
  }
]
  },
  {
    name: "campaign-engagement-clusters",
    path: "engagement-clusters",
    meta: engagement_45clusters9ZTiLS1zlgMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-clusters.vue")
  },
  {
    name: "campaign-engagement-dimension",
    path: "engagement-dimension",
    meta: engagement_45dimensionqLkFZ4XD6FMeta || {},
    redirect: engagement_45dimensionqLkFZ4XD6FMeta?.redirect,
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension.vue"),
    children: [
  {
    name: "campaign-engagement-dimension-manager",
    path: "manager",
    meta: managersNgXg7un8CMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/manager.vue")
  },
  {
    name: "campaign-engagement-dimension-organization",
    path: "organization",
    meta: organizationZL73pt1yGPMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/organization.vue")
  },
  {
    name: "campaign-engagement-dimension-team",
    path: "team",
    meta: teamy8wS22yJgbMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/team.vue")
  },
  {
    name: "campaign-engagement-dimension-work",
    path: "work",
    meta: workxOg2h5f8HiMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/engagement-dimension/work.vue")
  }
]
  },
  {
    name: enpsOKQcVarQRFMeta?.name,
    path: "enps",
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps.vue"),
    children: [
  {
    name: "campaign-enps-company",
    path: "company",
    meta: companySvU5EgUgrUMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/company.vue")
  },
  {
    name: "campaign-enps-default",
    path: "default",
    meta: defaultP9SPtZXvKdMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/default.vue")
  },
  {
    name: "campaign-enps",
    path: "",
    meta: indexHMpFQzq692Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/index.vue")
  },
  {
    name: "campaign-enps-leadership",
    path: "leadership",
    meta: leadershipvNSh39czXxMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/leadership.vue")
  },
  {
    name: "campaign-enps-product",
    path: "product",
    meta: productj5Bp0hmaLUMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/product.vue")
  },
  {
    name: "campaign-enps-team",
    path: "team",
    meta: teamplXnznDG4MMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/enps/team.vue")
  }
]
  },
  {
    name: "campaign-experience-dimension",
    path: "experience-dimension",
    meta: experience_45dimensionPS6utCkbZoMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/experience-dimension.vue")
  },
  {
    name: "campaign-family-dimension",
    path: "family-dimension",
    meta: family_45dimensionchwNZWiRtdMeta || {},
    redirect: family_45dimensionchwNZWiRtdMeta?.redirect,
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension.vue"),
    children: [
  {
    name: "campaign-family-dimension-body",
    path: "body",
    meta: bodyOYxH66ZCxfMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/body.vue")
  },
  {
    name: "campaign-family-dimension-feeling",
    path: "feeling",
    meta: feeling0DdI9PsM01Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/feeling.vue")
  },
  {
    name: "campaign-family-dimension-mind",
    path: "mind",
    meta: mindkajAyyNTHlMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/mind.vue")
  },
  {
    name: "campaign-family-dimension-soul",
    path: "soul",
    meta: soulgGACk310NIMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/family-dimension/soul.vue")
  }
]
  },
  {
    name: "campaign-flow",
    path: "flow",
    meta: flowAizkwUvWycMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/flow.vue")
  },
  {
    name: "campaign",
    path: "",
    meta: indexjrMckfdgJlMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/index.vue")
  },
  {
    name: "campaign-influencers",
    path: "influencers",
    meta: influencersyVNInMdxiRMeta || {},
    redirect: influencersyVNInMdxiRMeta?.redirect,
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers.vue"),
    children: [
  {
    name: "campaign-influencers-agents",
    path: "agents",
    meta: agentsdlMUFqoB8TMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/agents.vue")
  },
  {
    name: "campaign-influencers-experts",
    path: "experts",
    meta: expertsN4Sh4HHjwUMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/experts.vue")
  },
  {
    name: "campaign-influencers-mentors",
    path: "mentors",
    meta: mentors3WCQ8dr13qMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/influencers/mentors.vue")
  }
]
  },
  {
    name: "campaign-magic",
    path: "magic",
    meta: magic5bsxwbtBh0Meta || {},
    redirect: magic5bsxwbtBh0Meta?.redirect,
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic.vue"),
    children: [
  {
    name: "campaign-magic-autonomy",
    path: "autonomy",
    meta: autonomyYbr2DQxEEHMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/autonomy.vue")
  },
  {
    name: "campaign-magic-connection",
    path: "connection",
    meta: connectionVX6WRBgM4KMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/connection.vue")
  },
  {
    name: "campaign-magic-growth",
    path: "growth",
    meta: growthpxSjYCvd54Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/growth.vue")
  },
  {
    name: "campaign-magic-impact",
    path: "impact",
    meta: impactX9rHrZQv9qMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/impact.vue")
  },
  {
    name: "campaign-magic-meaning",
    path: "meaning",
    meta: meaningH38BsFRGGGMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/magic/meaning.vue")
  }
]
  },
  {
    name: open_45questionsczCuhXxD6iMeta?.name,
    path: "open-questions",
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions.vue"),
    children: [
  {
    name: "campaign-open-questions-highlights",
    path: "highlights",
    meta: highlights08ikfrQQlTMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions/highlights.vue")
  },
  {
    name: "campaign-open-questions",
    path: "",
    meta: indexLv3v5PgIOuMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/open-questions/index.vue")
  }
]
  },
  {
    name: "campaign-ranking-by-filter",
    path: "ranking-by-filter",
    meta: ranking_45by_45filterQVOXux3WXeMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/ranking-by-filter.vue")
  },
  {
    name: "campaign-ranking-by-question",
    path: "ranking-by-question",
    meta: ranking_45by_45questionq4kbXMb6FoMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/ranking-by-question.vue")
  },
  {
    name: "campaign-resume",
    path: "resume",
    meta: resumedVdvOHpj49Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/[[campaign]]/resume.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexb6lErPlMfHMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/index.vue")
  },
  {
    name: "inside-consultant-employee",
    path: "/inside-consultant-employee",
    meta: inside_45consultant_45employeevi5WhHnC9IMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/inside-consultant-employee.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login3EW15MZmp6Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/login.vue")
  },
  {
    name: "my-surveys",
    path: "/my-surveys",
    meta: my_45surveysBV5hAAKjSxMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/my-surveys.vue")
  },
  {
    name: "okrs",
    path: "/okrs",
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs.vue"),
    children: [
  {
    name: "okrs-plans-plan",
    path: "plans-:plan()",
    meta: indexJ2ngTVvOD0Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/index.vue")
  },
  {
    name: "okrs-plans-plan-objectives-objective-key-result-create",
    path: "plans-:plan()/objectives-:objective()/key-result-create",
    meta: key_45result_45createrimWIn2jhQMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-result-create.vue")
  },
  {
    name: "okrs-plans-plan-objectives-objective-key-results-keyResult-update",
    path: "plans-:plan()/objectives-:objective()/key-results-:keyResult()/update",
    meta: updateuRR19Rbno1Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/update.vue")
  },
  {
    name: "okrs-plans-plan-objectives-objective-key-results-keyResult-view",
    path: "plans-:plan()/objectives-:objective()/key-results-:keyResult()/view",
    meta: viewD23slLvSwTMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/key-results-[keyResult]/view.vue")
  },
  {
    name: "okrs-plans-plan-objectives-objective-update",
    path: "plans-:plan()/objectives-:objective()/update",
    meta: updateHH6um9fvdAMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-[objective]/update.vue")
  },
  {
    name: "okrs-plans-plan-objectives-create",
    path: "plans-:plan()/objectives-create",
    meta: objectives_45createbWidTWF19nMeta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-[plan]/objectives-create.vue")
  },
  {
    name: "okrs-plans-create",
    path: "plans-create",
    meta: plans_45createIpscOaKKj7Meta || {},
    component: () => import("/codebuild/output/src209259843/src/dashboard.app.winx.ai/pages/okrs/plans-create.vue")
  }
]
  }
]