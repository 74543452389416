const ptBr = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa mt-4">
    Dimensões
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      A opinião da família sobre o bem-estar dos colaboradores é importante para a empresa porque oferece uma
      <span class="underline decoration-2 underline-offset-4 decoration-sky-200">perspectiva externa</span> e
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">íntima</span>
      sobre a satisfação e saúde mental dos funcionários. Com os resultados a seguir, veja como as
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400">famílias</span>
      entendem como a sua empresa valoriza o bem-estar integral de seus colaboradores.
    </p>`,
  title: 'Dimensões da Família',
  _mind: {
    title: `Mente`,
    message: `A dimensão "mente" foca na saúde mental do colaborador, oferecendo suporte psicológico e promovendo pausas para reduzir o estresse. Ao priorizar a mente, a empresa assegura que os colaboradores estejam mentalmente preparados e motivados, contribuindo para um ambiente de trabalho mais produtivo.`,
  },
  _body: {
    title: `Corpo`,
    message: `A dimensão "corpo" foca na saúde física do colaborador. Isso inclui promover atividade física, oferecer ergonomia adequada no ambiente de trabalho, estimular uma rotina saudável e incentivar bons hábitos alimentares. Ao cuidar do corpo, a empresa garante que os colaboradores tenham energia e vitalidade, contribuindo para um ambiente de trabalho mais eficiente.`,
  },
  _soul: {
    title: `Espírito`,
    message: `A dimensão "espírito" foca no bem-estar espiritual do colaborador, promovendo valores como propósito, ética e conexão com o trabalho. Isso pode incluir momentos de reflexão, práticas de mindfulness e a criação de um ambiente onde os colaboradores se sintam valorizados e alinhados com os objetivos da empresa. Ao nutrir o espírito, a empresa fortalece a satisfação e o comprometimento dos colaboradores.`,
  },
  _feeling: {
    title: `Emoção`,
    message: `A dimensão "emoção" foca no bem-estar emocional do colaborador, promovendo um ambiente onde sentimentos são respeitados e gerenciados de forma saudável. Isso inclui oferecer suporte emocional, fomentar relações interpessoais positivas e criar uma cultura de empatia e compreensão. Ao cuidar das emoções, a empresa contribui para um clima organizacional mais harmonioso e colaborativo.`,
  },
};

const es = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa mt-4">
    Dimensiones
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      La opinión de la familia sobre el bienestar de los empleados es importante para la empresa porque ofrece una
      sobre la satisfacción y la salud mental de los empleados. Con los resultados a continuación,
      vea cómo las familias entienden cómo su empresa valora el bienestar integral de sus empleados.
      <span class="underline decoration-2 underline-offset-4 decoration-sky-200">perspectiva externa</span> e
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">íntima</span>
      sobre la satisfacción y la salud mental de los empleados. Con los resultados a continuación, vea cómo las
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400">familias</span>
      entienden cómo su empresa valora el bienestar integral de sus empleados.
    </p>`,
  title: 'Dimensiones Familiares',
  _mind: {
    title: `Mente`,
    message: `La dimensión "mente" se enfoca en la salud mental del colaborador, ofreciendo soporte psicológico y promoviendo pausas para reducir el estrés. Al priorizar la mente, la empresa asegura que los colaboradores estén mentalmente preparados y motivados, contribuyendo a un ambiente de trabajo más productivo.`,
  },
  _body: {
    title: `Cuerpo`,
    message: `La dimensión "cuerpo" se centra en la salud física del colaborador. Esto incluye promover la actividad física, ofrecer una ergonomía adecuada en el ambiente de trabajo, fomentar una rutina saludable e incentivar buenos hábitos alimenticios. Al cuidar del cuerpo, la empresa asegura que los colaboradores tengan energía y vitalidad, contribuyendo a un ambiente de trabajo más eficiente.`,
  },
  _soul: {
    title: `Espíritu`,
    message: `La dimensión "espíritu" se centra en el bienestar espiritual del colaborador, promoviendo valores como propósito, ética y conexión con el trabajo. Esto puede incluir momentos de reflexión, prácticas de mindfulness y la creación de un ambiente donde los colaboradores se sientan valorados y alineados con los objetivos de la empresa. Al nutrir el espíritu, la empresa fortalece la satisfacción y el compromiso de los colaboradores.`,
  },
  _feeling: {
    title: `Emoción`,
    message: `La dimensión "emoción" se centra en el bienestar emocional del colaborador, promoviendo un entorno donde los sentimientos sean respetados y gestionados de forma saludable. Esto incluye ofrecer apoyo emocional, fomentar relaciones interpersonales positivas y crear una cultura de empatía y comprensión. Al cuidar de las emociones, la empresa contribuye a un clima organizacional más armonioso y colaborativo.`,
  },
};

const en = {
  card: `
    <h3 class="nui-heading nui-heading-2xl nui-weight-normal nui-lead-normal mb-6 underline decoration-4 underline-offset-8 decoration-yellow-400 font-batusa mt-4">
    Dimensions
    </h3>
    <p class="nui-paragraph nui-paragraph-md nui-weight-normal nui-lead-normal text-left text-muted-800 dark:text-muted-200">
      Family feedback on employee well-being is important to your company because it provides an
      on employee satisfaction and mental health. With the results below,
      see how families understand how your company values the overall well-being of its employees.
      <span class="underline decoration-2 underline-offset-4 decoration-sky-200">outside</span>,
      <span class="underline decoration-2 underline-offset-4 decoration-teal-400">intimate perspective</span>
      on employee satisfaction and mental health. With the results below, see how
      <span class="underline decoration-2 underline-offset-4 decoration-orange-400">families</span>
      understand how your company values the overall well-being of its employees.
    </p>`,
  title: 'Family Dimensions',
  _mind: {
    title: `Mind`,
    message: `The "mind" dimension focuses on the mental health of employees, offering psychological support and promoting breaks to reduce stress. By prioritizing the mind, the company ensures that employees are mentally prepared and motivated, contributing to a more productive work environment.`,
  },
  _body: {
    title: `Body`,
    message: `The "body" dimension focuses on the physical health of the employee. This includes promoting physical activity, providing adequate ergonomics in the workplace, encouraging a healthy routine, and fostering good eating habits. By caring for the body, the company ensures that employees have energy and vitality, contributing to a more efficient work environment.`,
  },
  _soul: {
    title: `Spirit`,
    message: `The "spirit" dimension focuses on the spiritual well-being of the employee, promoting values such as purpose, ethics, and connection with work. This can include moments of reflection, mindfulness practices, and creating an environment where employees feel valued and aligned with the company's objectives. By nurturing the spirit, the company strengthens employee satisfaction and commitment.`,
  },
  _feeling: {
    title: `Emotion`,
    message: `The "emotion" dimension focuses on the employee's emotional well-being, promoting an environment where feelings are respected and managed healthily. This includes offering emotional support, fostering positive interpersonal relationships, and creating a culture of empathy and understanding. The company contributes to a more harmonious and collaborative organizational climate by taking care of emotions.`,
  },
};

export default {
  key: 'familyDimension',
  translations: {
    ptBr,
    es,
    en,
  },
};
